<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Based on the Gibb's free energy,
        <stemble-latex content="$\Delta\text{G,}$" />
        values given, which of the following reactions are spontaneous? Check all reactions that
        apply.
      </p>

      <v-simple-table>
        <tbody>
          <tr style="height: 15px">
            <td>
              <v-checkbox v-model="inputs.spontaneousRxns" value="a" :dense="true" />
            </td>
            <td>
              <stemble-latex content="$\ce{2 Mg(s) + O2(g) -> 2 MgO(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^{\circ} = -1137\,\text{kJ/mol}$" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td>
              <v-checkbox v-model="inputs.spontaneousRxns" value="b" :dense="true" />
            </td>
            <td>
              <stemble-latex content="$\ce{NH3(g) + HCl(g) -> NH4Cl(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^{\circ} = -91.1\,\text{kJ/mol}$" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td>
              <v-checkbox v-model="inputs.spontaneousRxns" value="c" :dense="true" />
            </td>
            <td>
              <stemble-latex content="$\ce{C(s) + H2O(l) -> CO(g) + H2(g)}$" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^{\circ} = 90.8\,\text{kJ/mol}$" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td>
              <v-checkbox v-model="inputs.spontaneousRxns" value="d" :dense="true" />
            </td>
            <td>
              <stemble-latex content="$\ce{2 H2O(g) -> 2 H2(g) + O2(g)}$" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^{\circ} = 457\,\text{kJ/mol}$" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td>
              <v-checkbox v-model="inputs.spontaneousRxns" value="e" :dense="true" />
            </td>
            <td>
              <stemble-latex content="$\ce{CO2(g) + 2 H2O(l) -> CH4(g) + 2 O2(g)}$" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^{\circ} = 820\,\text{kJ/mol}$" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td>
              <v-checkbox v-model="inputs.spontaneousRxns" value="f" :dense="true" />
            </td>
            <td>
              <stemble-latex content="$\ce{Ag+(aq) + Cl-(aq) -> AgCl(s)}$" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^{\circ} = -55.6\,\text{kJ/mol}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question280',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        spontaneousRxns: [],
      },
      options: [
        {text: '$\\text{A}$', value: 'a'},
        {text: '$\\text{B}$', value: 'b'},
        {text: '$\\text{C}$', value: 'c'},
        {text: '$\\text{D}$', value: 'd'},
        {text: '$\\text{E}$', value: 'e'},
        {text: '$\\text{F}$', value: 'f'},
      ],
    };
  },
};
</script>
